<template>
  <div class="flex h-screen flex-col bg-indigo-700">
    <div class="m-auto">
      <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup></script>
